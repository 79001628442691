<i18n lang="yaml">
pt:
  timeIsOver: 'O período de teste encerrou!'
  contactUs: 'Falar conosco'
  headlineStart: 'Libere o'
  headlineEnd: 'do seu RH'
  centralizeTool: 'Centralize todo o seu RH em uma só ferramenta'
  getAPlan: 'Contrate um plano:'
  superPower: 'Super poder'
  illustration: 'Ilustração de uma mulher levantando pesos'
en:
  timeIsOver: 'The trial period is over!'
  contactUs: 'Contact us'
  headlineStart: 'Unlock your'
  headlineEnd: 'HR potential'
  centralizeTool: 'Centralize all your HR in one tool'
  getAPlan: 'Get a plan:'
  superPower: 'Super power'
  illustration: 'Illustration of a woman lifting weights'
</i18n>

<template>
  <div class="the-paywall-wall">
    <div class="the-paywall-wall__content-wrapper">
      <p class="mb-5">
        {{ t('timeIsOver') }}
      </p>
      <div class="d-flex flex-column mx-3">
        <span class="align-self-start mb-2">{{ t('headlineStart') }}</span>
        <img
          src="public/zazos-super-power.svg"
          :alt="t('superPower')"
        >
        <span class="align-self-end">{{ t('headlineEnd') }}</span>
      </div>
      <img
        src="public/illustration-super-power.png"
        :alt="t('illustration')"
        class="the-paywall-wall__illustration"
      >
      <div class="the-paywall-wall__plans">
        <p>{{ t('centralizeTool') }}</p>
        <strong class="mb-4 d-block">{{ t('getAPlan') }}</strong>
        <v-btn
          class="elevation-0 mt-2 text-white"
          color="#FD8C41"
          size="large"
          :elevation="0"
          :disabled="hasClicked"
          @click="openContactUs"
        >
          {{ t('contactUs') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThePaywallWall',
  emits: ['ctaClick'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  data() {
    return {
      hasClicked: false,
    };
  },
  methods: {
    openContactUs() {
      this.hasClicked = true;
      this.$emit('ctaClick');
    },
  },
};
</script>

<style lang="scss">
.the-paywall-wall {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-block: 16px;
  background-color: rgb(2 46 85 / 60%);
  backdrop-filter: blur(3px);
  overflow: auto;
  z-index: 201;

  @supports (height: 100dvh) {
    width: 100dvw;
    height: 100dvh;
  }
}

.the-paywall-wall__content-wrapper {
  flex: 0;
  display: flex;
  flex-direction: column;
  max-width: 364px;
  width: 100%;
  margin-block: auto;
  padding-inline: 56px;
  padding-top: 88px;
  border-radius: 100vmax;
  background-color: white;
  text-align: center;
  letter-spacing: 1px;
  z-index: 1;
}

.the-paywall-wall__illustration {
  margin-inline: -108px;
  margin-bottom: -72px;
  margin-top: -16px;
  z-index: 1;
}

// this class is also used by intercom automation:
// https://app.intercom.com/a/apps/ci6rseap/operator/custom-bots/button-custom-bot/35202916?mode=edit&visual=enabled
.the-paywall-wall__plans {
  margin-inline: -56px;
  padding-inline: 56px;
  padding-top: 104px;
  padding-bottom: 88px;
  background-color: #022E54;
  border-radius: 0px 0px 100vmax 100vmax;
  color: white;
}
</style>
